import React, {FunctionComponent, useState, Fragment} from "react";
import {
    AppBar, Button,
    IconButton,
    List, ListItem, ListItemText,
    makeStyles,
    SwipeableDrawer,
    Toolbar,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import HorizontalSplitTwoToneIcon from '@material-ui/icons/HorizontalSplitTwoTone';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(5)
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    mobileTitle: {
        flexGrow: 1,
        paddingRight: theme.spacing(5)
    }
}))



const Header: FunctionComponent = () => {
    const classes = useStyles();

    const isUsingDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('sm'))



    const [drawerOpen, setDrawerOpen] = useState(false)

    const toggleDrawer = () => setDrawerOpen(!drawerOpen)

    const DesktopMenu = () => (
        <Button component={Link} to={'/soaps'}>
            <Typography variant={'h5'}>
                {'Soaps'}
            </Typography>
        </Button>
    )

    const NavLinkListItem = (props: {name: any, link: string}) => {
        const {name, link} = props
        return (
        <ListItem button component={Link} to={link} onClick={() => toggleDrawer()}>
            <ListItemText primary={name} />
        </ListItem>
    )}

    return (
        <Fragment>
            <AppBar position={'static'} className={classes.root} id={'top-anchor'}>
                <Toolbar>
                    <IconButton edge={'start'} className={classes.menuButton} onClick={() => toggleDrawer()}>
                        <HorizontalSplitTwoToneIcon />
                    </IconButton>
                    {isUsingDesktop ?
                        <div className={classes.title}>
                            <Button component={Link} to={'/'}>
                                <Typography variant={'h5'}>
                                    {'Steven Soaps'}
                                </Typography>
                            </Button>
                        </div> :
                        <Button component={Link} to={'/'} className={classes.mobileTitle}>
                            <Typography variant={'h5'}>
                                {'Steven Soaps'}
                            </Typography>
                        </Button>
                    }

                    {isUsingDesktop ?
                        <DesktopMenu /> :
                        null
                    }
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                onClose={() => toggleDrawer()}
                onOpen={() => toggleDrawer()}
                open={drawerOpen}>
                <List>
                    <NavLinkListItem name={'Home'} link={'/'}/>
                    <NavLinkListItem name={'Soaps'} link={'/soaps'}/>
                </List>
            </SwipeableDrawer>
        </Fragment>

    )
}

export default Header