import React from 'react';
import Home from "./pages/home";
import {Switch, Route} from 'react-router-dom';
import {createMuiTheme, ThemeProvider, makeStyles} from "@material-ui/core";
import Header from "./components/header";
import CssBaseline from "@material-ui/core/CssBaseline";
import Soaps from "./pages/soaps";
import NotFound from "./pages/NotFound";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#B8D8D8',
            // light: '#ffe5d4',
            // dark: '#68a691'
        },
        secondary: {
            main: '#4F6367',
            // light: '#5390d9',
            // dark: '#7400b8'
        },
        background: {
            default: '#EFF2F6'
        }
    }
})

const useStyles = makeStyles(theme => ({
    main: {
        height: '100vh'
    }
}))


const App = () => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={classes.main}>
                <Header />
                <Switch>
                    <Route exact path={'/'} component={Home} />
                    <Route path={'/soaps'} component={Soaps} />
                    <Route component={NotFound} />
                </Switch>
            </div>
        </ThemeProvider>
    )
}

export default App;
