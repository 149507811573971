import React, {useState, Fragment, PropsWithChildren} from "react";
import LandAndFire from '../../media/Land_And_Fire.webp';
import GroovySwirl from '../../media/Groovy_Swirl.webp';
import SodaPop from '../../media/Soda_Pop.webp';
import SunshineyDay from '../../media/Sunshiny_Day.webp';
import CinnamonMountain from '../../media/Cinnamon_Mountain.webp';
import BurstCross from '../../media/Burst_Cross.webp';
import AppleBubble from '../../media/Apple_Bubble.webp';
import MidnightWaters from '../../media/Midnight_Waters.webp';
import AlienPlanet from '../../media/Alien_Planet.webp';

import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia, Fab, Grow, makeStyles,
    Typography
} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import ScrollToTop from "../../components/scroll-to-top";
import {KeyboardArrowUp} from "@material-ui/icons";

interface SoapCard {
    name: string,
    pictureURL: string,
    description: string
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    card: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        maxWidth: theme.spacing(50),
        backgroundColor: theme.palette.primary.main
    },
    mediaContainer: {
        width: '100%',
        height: '100%'
    },
    media: {
        width: '100%',
        height: '100%'
    },
    skeletonMedia: {
        height: theme.spacing(35),
        width: theme.spacing(50),
    },
    mediaNotLoaded: {
        display: 'none'
    },
    mediaButton: {
        color: theme.palette.secondary.dark
    }
}))

const Soaps = (props: PropsWithChildren<any>) => {
    const classes = useStyles()

    const soaps: SoapCard[] = [
        // {
        //     name: 'Mystic Crystal',
        //     pictureURL: MysticCrystal,
        //     description: 'This mysterious soap uses the dark crystal scent, which will make you feel ' +
        //         'like you\'re exploring a misty cave. Bring out your inner adventurer!'
        // },
        {
            name: 'Land and Fire',
            pictureURL: LandAndFire,
            description: 'This lavish soap has the "Dragon\'s Blood" scent, and it shows! Made with' +
                'olive oil, coconut oil, conflict-free palm oil, avocado oil, shea butter and caster oil, ' +
                'this bar is great to use in the shower, and to spice up your sink!'
        },
        {
            name: 'Groovy Swirl',
            pictureURL: GroovySwirl,
            description: 'This premium "Jamaica me Crazy" scented soap will do just that! Teleport to ' +
                'the beach right from your shower with this delightful blend.'
        },
        {
            name: 'Soda Pop',
            pictureURL: SodaPop,
            description: 'Craving some orange soda pop but don\'t want the calories? This is the soap ' +
                'for you! Made with a special squeaky-clean focus that will leave you wanting more.'
        },
        {
            name: 'Sunshiny Day',
            pictureURL: SunshineyDay,
            description: 'This soap will remind you of a nice floury summer breeze. Made with the ' +
                '"Breezes and Sunshine" fragrence, you\'ll feel like a warm summer\'s day even on the coldest nights.'
        },
        {
            name: 'Cinnamon Mountain',
            pictureURL: CinnamonMountain,
            description: 'Ever wondered what a mountain made purely from apples and cinnamon would smell like?' +
                ' Guess no more! This soap will have you climbing that addicting cinnamon mountain. But be careful, ' +
                'you\'ll reach the top before you know it!'
        },
        {
            name: 'Burst Sash',
            pictureURL: BurstCross,
            description: 'This fruity, gummy scent will have you craving some candy! But don\'t worry, this ' +
                'delightful candy scent will leave your hands squeaky clean.'
        },
        {
            name: 'Apple Bubble',
            pictureURL: AppleBubble,
            description: 'This unique soap design was made using a bubble mold - the same kind that ' +
                'is normally reserved for high-end candles! With this soap\'s complex matrix of bubbles, ' +
                'you will get a unique and convenient shower experience. This soap has an \'Apple Sage\' fragrance.'
        },
        {
            name: 'Midnight Waters',
            pictureURL: MidnightWaters,
            description: 'With this serene soap, you\'ll smell like you\'re actually floating on a scenic lake ' +
                'at midnight. This is a very relaxing soap, use at your own risk!'
        },
        {
            name: 'Alien Planet',
            pictureURL: AlienPlanet,
            description: 'You\'ll be feeling out of this world with this outstanding soap design! ' +
                'Any aliens in your company will feel right at home with this black raspberry and vanilla scent.'
        },
    ]

    const numSoaps = soaps.length
    const [soapsLoaded, setSoapsLoaded] = useState(0)
    const [loadAllSoaps, setLoadAllSoaps] = useState(false)

    const onCardLoaded = () => {
        setSoapsLoaded(soapsLoaded + 1)
        if (soapsLoaded === numSoaps) {
            setLoadAllSoaps(true)
        }
    }

    const Soap = (props: {name: string, pictureURL: string, description: string}) => {
        const {name, pictureURL, description} = props

        // If loadCard is not checked, it will indefinitely call onLoad since it will keep getting re-rendered.
        const callLoadIfNotComplete = () => {
            if (!loadAllSoaps) {
                onCardLoaded()
            }
        }

        return (
            <Fragment>
                <Grow in={loadAllSoaps}>
                    <Card className={loadAllSoaps? classes.card : classes.mediaNotLoaded}>
                        <CardHeader title={name} />
                        <CardActionArea>
                            <CardMedia
                                className={classes.mediaContainer}
                                title={name}
                            >
                                <img
                                    className={classes.media}
                                    src={pictureURL}
                                    alt={name}
                                    onLoad={callLoadIfNotComplete}/>
                            </CardMedia>
                            <CardContent>
                                <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
                                    {description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size={'small'} className={classes.mediaButton}>
                                {'Learn More'}
                            </Button>
                        </CardActions>
                    </Card>
                </Grow>
                {loadAllSoaps ? null :
                    <Card className={classes.card}>
                        <CardHeader title={<Skeleton height={10} width="45%" style={{ marginBottom: 6 }} />} />
                        <CardActionArea>
                            <CardMedia
                                className={classes.mediaContainer}
                            >
                                <Skeleton variant="rect" className={classes.skeletonMedia} />
                            </CardMedia>
                            <CardContent>
                                <Skeleton height={10} width="100%" style={{ marginBottom: 6 }} />
                                <Skeleton height={10} width="100%" style={{ marginBottom: 6 }} />
                                <Skeleton height={10} width="100%" style={{ marginBottom: 6 }} />
                                <Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Skeleton height={10} width="10%" style={{ marginBottom: 6 }} />
                        </CardActions>
                    </Card>
                }
            </Fragment>

        )
    }

    return (
    <div className={classes.root}>
        {soaps.map((soap: SoapCard) =>
            <Soap
                key={soap.name}
                name={soap.name}
                pictureURL={soap.pictureURL}
                description={soap.description}/>
        ) }
        <ScrollToTop {...props}>
            <Fab color={'secondary'} size={'small'} aria-label={'Scroll to top'}>
                <KeyboardArrowUp />
            </Fab>
        </ScrollToTop>
    </div>
)}

export default Soaps;