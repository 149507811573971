import React from "react";
import {Button, Container, makeStyles, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    item: {
        marginTop: theme.spacing(2)
    }
}))

const Home = () => {
    const classes = useStyles();

    return (
        <Container component={'main'} maxWidth={'md'}>
            <div className={classes.content}>
                <Typography component={'h1'} variant={'h2'} className={classes.item} align={'center'}>
                    {'Soaps that you\'ll love! 🎉'}
                </Typography>
                <Button component={Link} to={'/soaps'} variant={'contained'} className={classes.item} disableElevation color={'primary'}>
                    <Typography variant={'h5'}>
                        {'See the Soaps'}
                    </Typography>
                </Button>
            </div>
        </Container>
    )
}

export default Home