import React, {PropsWithChildren} from "react";
import {makeStyles, useScrollTrigger, Zoom} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
}))

const ScrollToTop = (props: PropsWithChildren<any>) => {
    const {window, children} = props
    const classes = useStyles()
    const scrollTrigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 200
    })

    const handleBackToTopClick = (event: any) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#top-anchor')

        if (anchor) {
            anchor.scrollIntoView({behavior: 'smooth', block: 'center'})
        }
    }

    return (
        <Zoom in={scrollTrigger}>
            <div onClick={handleBackToTopClick} role={'presentation'} className={classes.root}>
                {children}
            </div>
        </Zoom>
    )
}

export default ScrollToTop;