import React from "react";
import {Button, Container, makeStyles, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    item: {
        marginTop: theme.spacing(2)
    }
}))

const NotFound = () => {
    const classes = useStyles();

    return (
        <Container component={'main'} maxWidth={'xs'}>
            <div className={classes.content}>
                <Typography component={'h1'} variant={'h5'} className={classes.item} align={'center'}>
                    {'Soap Not Found! 🧼'}
                </Typography>
                <Button component={Link} to={'/'} variant={'contained'} className={classes.item} disableElevation color={'primary'}>
                    <Typography variant={'h5'}>
                        {'Home'}
                    </Typography>
                </Button>
            </div>
        </Container>
    )
}

export default NotFound